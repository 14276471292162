/* global iurioData, require, module */
var iurio = iurio || {}; //eslint-disable-line

if (typeof require !== 'undefined') {
	iurio.crypto = require('./crypto.js');
}

/** @namespace */
iurio.api = {
	/** @namespace */
	admin: {
		/**
		 * Create system user
		 *
		 * @param {string} username
		 * @param {string} emailAddress
		 * @param {string} firstName
		 * @param {string} lastName
		 * @param {(string|ArrayBuffer)} secret
		 * @param {Object} iurioKey
		 * @return {Promise<Object>} the resData object
		 */
		createSystemUser: async function (username, emailAddress, firstName, lastName, secret, iurioKey) {
			const method = 'POST';
			const url = '/api/v1/admin/create-system-user';

			const signupData = await iurio.crypto.handler.beforeSystemUserCreation(username, secret, iurioKey, iurio.api.entrance.getLoginName);
			const params = {
				username: signupData[0],
				firstName: firstName,
				lastName: lastName,
				emailAddress: emailAddress,
				privateKey: signupData[1],
				salt: signupData[2],
				publicKey: signupData[3],
				encryptedIurioKey: signupData[4],
				password: signupData[5]
			};

			const onSuccess = async function (user) {
				const privKey = signupData[6];
				const exported = await window.crypto.subtle.exportKey('pkcs8', privKey);
				const keyString = iurio.crypto.utils.arrayBufferToB64String(exported);
				return {
					userID: user.id,
					key: keyString,
				};
			};

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},


		/**
		 * Invite (internal) users
		 *
		 * @param {string[]} emailAddresses
		 * @param {Object} officeKey
		 * @param {Object} iurioKey
		 * @param {Object[]} allUsers
		 * @param {boolean[]} [isAdminList]
		 * @param {string[]} [names]
		 * @return {Promise<Object>} the resData object
		 */
		inviteInternalUsers: async function (emailAddresses, officeKey, iurioKey, allUsers, isAdminList, names) {
			if (emailAddresses.length === 0) {
				return;
			}

			const method = 'POST';
			const url = '/api/v1/admin/invite';

			const keyData = await iurio.crypto.handler.beforeInviteUsers(emailAddresses, allUsers, undefined, officeKey, iurioKey);
			const params = {
				emailAddresses: emailAddresses,
				privKeys: keyData.privKeys,
				pubKeys: keyData.pubKeys,
				officeKeys: keyData.officeKeys,
				iurioKeys: keyData.iurioKeys,
			};
			if (isAdminList) {
				params.isAdminList = isAdminList;
			}
			if (names) {
				params.names = names;
			}

			const onSuccess = async function (users) {
				for (const user of users) {
					const keyIndex = emailAddresses.findIndex(function (email) { return email.toLowerCase() === user.email.toLowerCase(); });
					const key = keyData.keys[keyIndex];
					await iurio.api.utils.sendMail(user.emailToken, user.subject, user.body, user.name, key);
				}
				return users;
			};

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * List user's keys
		 *
		 * @param {number} userID
		 * @return {Promise<Object[]>} the resData object
		 */
		listAllKeys: async function (userID) {
			const method = 'GET';
			const url = '/api/v1/admin/list-all-keys';

			const params = { userID };

			const onSuccess = iurio.crypto.handler.onListAllUserKeys;

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * List log entries
		 *
		 * @param {number} [userID]
		 * @param {number} [projectID]
		 * @param {number} [workspaceID]
		 * @return {Promise<Object[]>} the resData object
		 */
		listLog: async function (userID, projectID, workspaceID) {
			const method = 'GET';
			const url = '/api/v1/admin/logging/list';

			const params = {};
			if (userID) {
				params.userID = userID;
			}
			if (projectID) {
				params.projectID = projectID;
			}
			if (workspaceID) {
				params.workspaceID = workspaceID;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Reset user's authentication
		 *
		 * @param {number} userID
		 * @param {Object[]} userKeys
		 * @return {Promise<Object>} the resData object
		 */
		resetUserAuthentication: async function (userID, userKeys) {
			const method = 'POST';
			const url = '/api/v1/admin/reset-user-authentication';

			const keyData = await iurio.crypto.handler.beforeResetUser(userKeys);
			const params = {
				userID: userID,
				privKey: keyData.privKey,
				pubKey: keyData.pubKey,
				userKeys: keyData.userKeys,
			};

			const onSuccess = response => iurio.api.utils.sendMail(response.emailToken, response.subject, response.body, response.name, keyData.key);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Update user details
		 *
		 * @param {Object} user
		 * @param {string} firstName
		 * @param {string} lastName
		 * @param {string} emailAddress
		 * @param {boolean} isDisabled
		 * @param {string} [company]
		 * @param {boolean} [isAdmin]
		 * @param {Object[]} [userKeys]
		 * @param {number[]} [takeoverProjectIDs]
		 * @return {Promise<Object>} the resData object
		 */
		updateUserDetails: async function (user, firstName, lastName, emailAddress, isDisabled, company, isAdmin, userKeys, takeoverProjectIDs) {
			const method = 'POST';
			const url = '/api/v1/admin/update-user-details';

			const params = {
				userId: user.id,
				firstName: firstName,
				lastName: lastName,
				emailAddress: emailAddress,
				isDisabled: isDisabled,
			};
			if (company) {
				params.company = company;
			}
			if (typeof isAdmin !== 'undefined') {
				params.isAdmin = isAdmin;
				if (isAdmin) {
					params.userKeys = await iurio.crypto.handler.beforePromoteToSysAdmin(userKeys, user);
				}
			}
			if (takeoverProjectIDs) {
				params.takeoverProjectIDs = takeoverProjectIDs;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Remove user
		 *
		 * @param {number} userId
		 * @return {Promise<Object>} the resData object
		 */
		removeUser: async function (userId) {
			const method = 'DELETE';
			const url = '/api/v1/admin/user/' + userId;
			const params = { userId };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get billing data
		 *
		 * @return {Promise<Object>} the resData object
		 */
		getBillingData: async function (startDate, endDate) {
			const method = 'GET';
			const url = '/api/v1/admin/billing/get-billing-data';
			const params = {
				startDate: startDate.getTime(),
				endDate: endDate.getTime(),
			};
			const onSuccess = iurio.crypto.handler.onGetBillingData;

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/** @namespace */
		branding: {
			/**
			 * Set header logo
			 *
			 * @param {number} index
			 * @return {Promise<Object>} the resData object
			 */
			setHeaderLogo: async function (index) {
				const method = 'PUT';
				const url = '/api/v1/admin/branding/header-logo';
				const params = { logoIndex: index };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Set secondary logo
			 *
			 * @param {number} index
			 * @return {Promise<Object>} the resData object
			 */
			setSecondaryLogo: async function (index) {
				const method = 'PUT';
				const url = '/api/v1/admin/branding/secondary-logo';
				const params = { logoIndex: index };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Remove primary/secondary logo
			 *
			 * @param {boolean} isPrimary
			 * @param {number} logoIndex
			 * @return {Promise<Object>} the resData object
			 */
			removeLogo: async function (isPrimary, logoIndex) {
				const method = 'DELETE';
				const url = '/api/v1/admin/branding/' + (isPrimary ? 'header-logo' : 'secondary-logo');
				const params = { logoIndex };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * List primary/secondary logos
			 *
			 * @param {boolean} isPrimary
			 * @return {Promise<Object>} the resData object
			 */
			listLogos: async function (isPrimary) {
				const method = 'GET';
				const url = '/api/v1/admin/branding/' + (isPrimary ? 'header-logo' : 'secondary-logo');

				return iurio.api.utils.sendRequest(method, url);
			},

			/**
			 * Upload primary/secondary logo
			 *
			 * @param {boolean} isPrimary
			 * @param {File} fileObject
			 * @param {string} fileName
			 * @return {Promise<Object>} the resData object
			 */
			uploadLogo: async function (isPrimary, fileObject, fileName) {
				const method = 'POST';
				const url = '/api/v1/admin/branding/' + (isPrimary ? 'header-logo' : 'secondary-logo');
				const params = new FormData();
				if (iurioData.csrf) {
					params.append('_csrf', iurioData.csrf);
				}
				params.append('file', fileObject, fileName);

				const xhr = new XMLHttpRequest();
				return new Promise(function (resolve, reject) {
					xhr.open(method, url, true);

					xhr.responseType = 'text';
					xhr.onload = async function () {
						resolve(this.response);
					};
					xhr.onerror = reject;
					xhr.send(params);
				});
			},
		},

		/** @namespace */
		insights: {
			/**
			 * List insights
			 *
			 * @return {Promise<Object>} the resData object
			 */
			list: async function () {
				const method = 'GET';
				const url = '/api/v1/admin/insights';

				return iurio.api.utils.sendRequest(method, url);
			},

			/**
			 * Add insight
			 *
			 * @param {string} name
			 * @param {number} type
			 * @param {Object} [options]
			 * @return {Promise<Object>} the resData object
			 */
			add: async function (name, type, options) {
				const method = 'POST';
				const url = '/api/v1/admin/insights';

				const params = {
					name,
					type
				};
				if (options) {
					params.options = options;
				}

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Edit insight
			 *
			 * @param {number} insightID
			 * @param {string} [name]
			 * @param {number} [type]
			 * @param {Object} [options]
			 * @return {Promise<Object>} the resData object
			 */
			edit: async function (insightID, name, type, options) {
				const method = 'PATCH';
				const url = '/api/v1/admin/insights/' + insightID;

				const params = {};
				if (name) {
					params.name = name;
				}
				if (type) {
					params.type = type;
				}
				if (options) {
					params.options = options;
				}

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Move insight
			 *
			 * @param {number} insightID
			 * @param {boolean} up
			 * @return {Promise<Object>} the resData object
			 */
			move: async function (insightID, up) {
				const method = 'POST';
				const url = '/api/v1/admin/insights/' + insightID + '/move';
				const params = { up };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Remove insight
			 *
			 * @param {number} insightID
			 * @return {Promise<Object>} the resData object
			 */
			remove: async function (insightID) {
				const method = 'DELETE';
				const url = '/api/v1/admin/insights/' + insightID;

				return iurio.api.utils.sendRequest(method, url);
			},

			/** @namespace */
			chart: {
				/**
				 * Active users
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				activeUsers: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/active-users';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Documents signed
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				documentsSigned: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/documents-signed';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * External users invited/registered
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				externalUsersInvitedRegistered: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/external-users-invited-registered';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Files downloaded
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				filesDownloaded: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/files-downloaded';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Files edited
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				filesEdited: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/files-edited';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Files linked to tasks
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				filesLinkedToTasks: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/files-linked-to-tasks';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Files uploaded
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				filesUploaded: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/files-uploaded';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Most chat messages
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				mostChatMessages: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/most-chat-messages';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Most files uploaded
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				mostFilesUploaded: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/most-files-uploaded';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Most tasks
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				mostTasks: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/most-tasks';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Project creation to archival
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				projectCreationToArchival: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/project-creation-to-archival';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Projects created from templates
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				projectsCreatedFromTemplates: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/projects-created-from-templates';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Created projects
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				projectsCreated: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/projects-created';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Reaction time to emails
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				reactionTimeToEmails: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/reaction-time-to-emails';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Sent messages
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				sentMessages: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/sent-messages';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Storage used
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				storageUsed: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/storage-used';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Sum active projects
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				activeProjects: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/active-projects';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Sum active workspaces
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				activeWorkspaces: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/active-workspaces';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Tasks vs tasks done
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				tasksComparedToDoneTasks: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/tasks-compared-to-done-tasks';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Tasks created
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				tasksCreated: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/tasks-created';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Tasks edited/moved
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				tasksEditedMoved: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/tasks-edited-moved';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Tasks done
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				tasksDone: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/tasks-done';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Tasks past due date
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				tasksPastDue: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/tasks-past-due';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * User duration in project/workspace
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				userDurationInProjectWorkspace: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/user-duration-in-project-workspace';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * User duration logged in
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				userDurationLoggedIn: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/user-duration-logged-in';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * User with most created projects
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				userWithMostCreatedProjects: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/user-with-most-created-projects';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * User workload - due and overdue tasks
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				userWorkloadDueAndOverdueTasks: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/user-workload-due-and-overdue-tasks';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Users per project/workspace
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				usersPerProjectWorkspace: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/users-per-project-workspace';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Workspace with longest user duration
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				workspaceWithLongestUserDuration: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/workspace-with-longest-user-duration';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Created workspaces
				 * @param {Object} options
				 *
				 * @return {Promise<Object>} the resData object
				 */
				workspacesCreated: async function (options) {
					const method = 'GET';
					const url = '/api/v1/admin/insights/workspaces-created';
					const params = options;

					return iurio.api.utils.sendRequest(method, url, params);
				},
			}
		},

		/**
		 * nazar
		 *
		 * @param {number} fromDate
		 * @param {number} toDate
		 * @return {Promise<Object>} the resData object
		 */
		nazar: async function (fromDate, toDate) {
			const method = 'GET';
			const url = '/api/v1/nazar';

			const params = {
				fromDate,
				toDate
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},
	},

	/** @namespace */
	entrance: {
		/**
		 * Login
		 *
		 * @param {string} loginName
		 * @param {number} authType
		 * @param {Object} authObjectData
		 * @param {Object} [firebaseData]
		 * @param {boolean} [rememberMe]
		 * @return {Promise<Object>} the resData object
		 */
		login: async function (loginName, authType, authObjectData, firebaseData, rememberMe) {
			const method = 'PUT';
			const url = '/api/v1/entrance/login';

			const authData = await iurio.crypto.handler.beforeAuth(authType, authObjectData, iurio.api, loginName);
			const params = {
				username: loginName,
				authType,
				authData,
			};
			if (firebaseData) {
				params.firebaseDeviceToken = firebaseData.token;
				params.firebaseDeviceDisplayName = firebaseData.device;
			}
			if (rememberMe) {
				params.rememberMe = rememberMe;
			}

			const onSuccess = user => iurio.crypto.handler.onLogin(user, authType, authObjectData);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Cancel remote authentication
		 *
		 * @param {string} loginName
		 * @param {string} challenge
		 */
		cancelRemoteAuthentication: async function (loginName, challenge) {
			const method = 'POST';
			const url = '/api/v1/entrance/cancel-remote-authentication';
			const params = {
				username: loginName,
				challenge,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get Login Name
		 *
		 * @param {string} username
		 * @return {Promise<string>} the login string
		 */
		getLoginName: async function (username) {
			const method = 'GET';
			const url = '/api/v1/entrance/get-login-salt';

			const hashedUsername = await iurio.crypto.handler.beforeGetLoginSalt(username);
			const params = { username: hashedUsername };

			const onSuccess = salt => iurio.crypto.handler.onGetLoginSalt(username, salt);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Get Password Salt
		 *
		 * @param {string} loginName
		 * @param {boolean} [recover]
		 * @return {Promise<string>} the salt string
		 */
		getPasswordSalt: async function (loginName, recover) {
			const method = 'GET';
			const url = '/api/v1/entrance/get-password-salt';

			const params = { username: loginName };
			if (recover) {
				params.recover = recover;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get public key credential request options for token authentication
		 *
		 * @param {string} loginName
		 * @param {string} [disallowedCredentialId]
		 * @param {string} [challengeToInvalidate] set if old challenge needs to be invalidated
		 * @param {string} [username] set if authenticating from app
		 * @param {CryptoKey} [sharedKey] set if authenticating from app
		 * @return {Promise<Object>} credentialRequestOptions
		 */
		startAuthentication: async function (loginName, disallowedCredentialId, challengeToInvalidate, username, sharedKey) {
			const method = 'POST';
			const url = '/api/v1/entrance/start-authentication';

			const params = { username: loginName };
			if (challengeToInvalidate) {
				params.challengeToInvalidate = challengeToInvalidate;
			}
			if (username && sharedKey) {
				params.encryptedUsername = await iurio.crypto.text.encryptSymmetric(username, sharedKey);
			}

			const onSuccess = credentialRequestOptions => iurio.crypto.handler.onStartAuthentication(credentialRequestOptions, disallowedCredentialId);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Start remote authentication
		 *
		 * @param {string} challenge
		 * @param {CryptoKey} [sharedKey] set if authenticating from app
		 */
		startRemoteAuthentication: async function (challenge, sharedKey) {
			const method = 'POST';
			const url = '/api/v1/entrance/start-remote-authentication';

			const params = { challenge };

			if (!sharedKey) {
				return iurio.api.utils.sendRequest(method, url, params);
			}

			params.getCredentialRequestOptions = true;

			const onSuccess = async result => {
				result.username = await iurio.crypto.text.decryptSymmetric(result.encryptedUsername, sharedKey);
				return result;
			};

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Send remote authentication message
		 *
		 * @param {string} challenge
		 * @param {any} message
		 */
		sendRemoteAuthenticationMessage: async function (challenge, message) {
			const method = 'PUT';
			const url = '/api/v1/entrance/send-remote-authentication-message';
			const params = {
				challenge,
				message,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Start remote registration
		 *
		 * @param {string} challenge
		 * @param {CryptoKey} [sharedKey] set if registering from app
		 */
		startRemoteRegistration: async function (challenge, sharedKey) {
			const method = 'POST';
			const url = '/api/v1/entrance/start-remote-registration';

			const params = { challenge };

			if (!sharedKey) {
				return iurio.api.utils.sendRequest(method, url, params);
			}

			params.getCredentialCreationOptions = true;

			const onSuccess = async result => {
				result.username = await iurio.crypto.text.decryptSymmetric(result.encryptedUsername, sharedKey);
				return result;
			};

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Send remote registration message
		 *
		 * @param {string} challenge
		 * @param {any} message
		 */
		sendRemoteRegistrationMessage: async function (challenge, message) {
			const method = 'PUT';
			const url = '/api/v1/entrance/send-remote-registration-message';
			const params = {
				challenge,
				message,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Signup first user
		 *
		 * @param {string} username
		 * @param {string} emailAddress
		 * @param {string} firstName
		 * @param {string} lastName
		 * @param {string} backupPassword
		 * @param {(string|ArrayBuffer)} secret
		 * @param {Object[]} systemUsers
		 * @param {number} systemUsers.id
		 * @param {string} systemUsers.publicKey
		 * @param {Object} [credential]
		 * @param {string} [deviceName]
		 * @param {boolean} [isMobile]
		 * @return {Promise<Object>} the resData object
		 */
		signupFirstUser: async function (username, emailAddress, firstName, lastName, backupPassword, secret, systemUsers, credential, deviceName, isMobile) {
			const method = 'POST';
			const url = '/api/v1/entrance/signup';

			const signupData = await iurio.crypto.handler.beforeSignupFirstUser(username, backupPassword, secret, iurio.api.entrance.getLoginName, systemUsers, credential && credential.response, deviceName);
			const keyPair = signupData[3];
			const params = {
				username: signupData[0],
				firstName,
				lastName,
				emailAddress,
				privateKey: signupData[1],
				salt: signupData[2],
				backupPassword: signupData[4],
				privateBackupKey: signupData[5],
				backupSalt: signupData[6],
				publicKey: signupData[7],
				encryptedOfficeKey: signupData[8],
				encryptedIurioKeys: signupData[9],
			};
			if (!credential) {
				params.password = signupData[10];
			} else {
				params.clientDataJSON = signupData[10];
				params.attestation = signupData[11];
				if (deviceName) {
					params.deviceName = signupData[12];
				}
				if (isMobile) {
					params.isMobile = isMobile;
				}
			}

			const onSuccess = () => iurio.crypto.handler.onSignup(keyPair);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Signup internal user
		 *
		 * @param {string} username
		 * @param {string} emailAddress
		 * @param {string} firstName
		 * @param {string} lastName
		 * @param {string} encryptedPrivateKey
		 * @param {string} pubKey
		 * @param {string} key
		 * @param {(string|ArrayBuffer)} secret
		 * @param {Object} [credential]
		 * @param {string} [deviceName]
		 * @param {boolean} [isMobile]
		 * @return {Promise<Object>} the resData object
		 */
		signupInternal: async function (username, emailAddress, firstName, lastName, encryptedPrivateKey, pubKey, key, secret, credential, deviceName, isMobile) {
			const method = 'POST';
			const url = '/api/v1/entrance/signup';

			const credentialResponse = credential ? credential.response : undefined;
			const signupData = await iurio.crypto.handler.beforeInviteSignup(username, encryptedPrivateKey, pubKey, key, secret, iurio.api.entrance.getLoginName, credentialResponse, deviceName);
			const hashedUsername = signupData[0];
			const wrappedPrivKey = signupData[1];
			const salt = signupData[2];
			const keyPair = signupData[3];

			const params = {
				username: hashedUsername,
				emailAddress: emailAddress,
				firstName: firstName,
				lastName: lastName,
				privateKey: wrappedPrivKey,
				salt: salt,
			};
			if (credential) {
				params.clientDataJSON = signupData[4];
				params.attestation = signupData[5];
				if (deviceName) {
					params.deviceName = signupData[6];
				}
				if (isMobile) {
					params.isMobile = isMobile;
				}
			} else {
				params.password = signupData[4];
			}

			const onSuccess = () => iurio.crypto.handler.onSignup(keyPair);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Signup external user
		 *
		 * @param {string} username
		 * @param {string} emailAddress
		 * @param {string} firstName
		 * @param {string} lastName
		 * @param {string} gender
		 * @param {string} company
		 * @param {string} encryptedPrivateKey
		 * @param {string} pubKey
		 * @param {string} key
		 * @param {(string|ArrayBuffer)} secret
		 * @param {Object} [credential]
		 * @param {string} [deviceName]
		 * @param {boolean} [isMobile]
		 * @return {Promise<Object>} the resData object
		 */
		signupExternal: async function (username, emailAddress, firstName, lastName, gender, company, encryptedPrivateKey, pubKey, key, secret, credential, deviceName, isMobile) {
			const method = 'POST';
			const url = '/api/v1/entrance/signup';

			const credentialResponse = credential ? credential.response : undefined;
			const signupData = await iurio.crypto.handler.beforeInviteSignup(username, encryptedPrivateKey, pubKey, key, secret, iurio.api.entrance.getLoginName, credentialResponse, deviceName);
			const hashedUsername = signupData[0];
			const wrappedPrivKey = signupData[1];
			const salt = signupData[2];
			const keyPair = signupData[3];
			const params = {
				username: hashedUsername,
				emailAddress: emailAddress,
				firstName: firstName,
				lastName: lastName,
				gender: gender,
				company: company,
				privateKey: wrappedPrivKey,
				salt: salt,
			};
			if (credential) {
				params.clientDataJSON = signupData[4];
				params.attestation = signupData[5];
				if (deviceName) {
					params.deviceName = signupData[6];
				}
				if (isMobile) {
					params.isMobile = isMobile;
				}
			} else {
				params.password = signupData[4];
			}

			const onSuccess = () => iurio.crypto.handler.onSignup(keyPair);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},
	},

	/** @namespace */
	account: {
		/**
		 * Update users password
		 *
		 * @param {string} loginName
		 * @param {number} authType
		 * @param {Object} authObjectData
		 * @param {string} password
		 * @param {boolean} [useBackup]
		 * @return {Promise<Object>} the resData object
		 */
		updatePassword: async function (loginName, authType, authObjectData, password, useBackup) {
			const method = 'PUT';
			const url = '/api/v1/account/update-password';

			const authData = await iurio.crypto.handler.beforeAuth(authType, authObjectData, iurio.api);
			const keys = await iurio.crypto.handler.beforeUpdatePassword(password);
			const loginToken = keys[0];
			const privateKey = keys[1];
			const salt = keys[2];
			const params = {
				username: loginName,
				authType,
				authData,
				password: loginToken,
				privateKey,
				salt,
				useBackup,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get public key credential creation options for first user token registration
		 *
		 * @param {string} firstName
		 * @param {string} lastName
		 * @param {string} [challengeToInvalidate] set if old challenge needs to be invalidated
		 * @return {Promise<Object>} credentialCreationOptions
		 */
		startRegistrationFirstUser: async function (firstName, lastName, challengeToInvalidate) {
			const method = 'POST';
			const url = '/api/v1/account/start-registration';

			const params = {
				firstName,
				lastName,
			};
			if (challengeToInvalidate) {
				params.challengeToInvalidate = challengeToInvalidate;
			}

			const onSuccess = iurio.crypto.handler.onStartRegistration;

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Get public key credential creation options for token registration
		 *
		 * @param {string} [challengeToInvalidate] set if old challenge needs to be invalidated
		 * @param {string} [username] set if authenticating from app
		 * @param {CryptoKey} [sharedKey] set if authenticating from app
		 * @return {Promise<Object>} credentialCreationOptions
		 */
		startRegistration: async function (challengeToInvalidate, username, sharedKey) {
			const method = 'POST';
			const url = '/api/v1/account/start-registration';

			const params = {};
			if (challengeToInvalidate) {
				params.challengeToInvalidate = challengeToInvalidate;
			}
			if (username && sharedKey) {
				params.encryptedUsername = await iurio.crypto.text.encryptSymmetric(username, sharedKey);
			}

			const onSuccess = iurio.crypto.handler.onStartRegistration;

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Get list of authenticators
		 *
		 * @return {Promise<Object[]>} authenticators
		 */
		listAuthenticators: async function () {
			const method = 'GET';
			const url = '/api/v1/account/list-authenticators';
			const onSuccess = iurio.crypto.handler.onListAuthenticators;

			return iurio.api.utils.sendRequest(method, url, onSuccess);
		},

		/**
		 * Get list of my tasks
		 *
		 * @return {Promise<Object[]>} tasks
		 */
		 listMyTasks: async function (workspaces) {
			const method = 'GET';
			const url = '/api/v1/account/tasks';

			const onSuccess = resData => iurio.crypto.handler.onListMyTasks(resData, workspaces);

			return iurio.api.utils.sendRequest(method, url, onSuccess);
		},

		/**
		 * Register an authentication token
		 *
		 * @param {string} loginName
		 * @param {number} authType
		 * @param {Object} authObjectData
		 * @param {Object} credential
		 * @param {ArrayBuffer} secret
		 * @param {string} deviceName
		 * @param {boolean} [isMobile]
		 */
		registerToken: async function (loginName, authType, authObjectData, credential, secret, deviceName, isMobile) {
			const method = 'PUT';
			const url = '/api/v1/account/token';

			const authData = await iurio.crypto.handler.beforeAuth(authType, authObjectData, iurio.api);
			const registrationData = await iurio.crypto.handler.beforeRegisterToken(credential.response, secret, deviceName);
			const params = {
				username: loginName,
				authType,
				authData,
				clientDataJSON: registrationData.clientDataJSON,
				attestation: registrationData.attestation,
				privateKey: registrationData.encryptedPrivateKey,
				salt: registrationData.salt,
			};
			if (deviceName) {
				params.deviceName = registrationData.encryptedDeviceName;
			}
			if (isMobile) {
				params.isMobile = isMobile;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Remove an authentication token
		 *
		 * @param {string} loginName
		 * @param {number} authType
		 * @param {Object} authObjectData
		 * @param {number} tokenId
		 */
		removeToken: async function (loginName, authType, authObjectData, tokenId) {
			const method = 'DELETE';
			const url = '/api/v1/account/token';

			const authData = await iurio.crypto.handler.beforeAuth(authType, authObjectData, iurio.api);
			const params = {
				username: loginName,
				authType,
				authData,
				tokenId,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Rename an authentication token
		 *
		 * @param {number} tokenId
		 * @param {string} deviceName
		 * @param {number} authType
		 * @param {Object} authObjectData
		 */
		renameToken: async function (tokenId, deviceName) {
			const method = 'PUT';
			const url = '/api/v1/account/token/name';

			const name = await iurio.crypto.handler.beforeRenameToken(deviceName);
			const params = {
				tokenId,
				name,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Cancel remote registration
		 *
		 * @param {string} challenge
		 */
		cancelRemoteRegistration: async function (challenge) {
			const method = 'POST';
			const url = '/api/v1/account/cancel-remote-registration';
			const params = { challenge };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * List user favorite colors
		 *
		 * @return {Promise<Object>} the resData object
		 */
		listColors: async function () {
			const method = 'GET';
			const url = '/api/v1/account/colors';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * List user settings
		 *
		 * @return {Promise<Object>} the resData object
		 */
		 listSettings: async function () {
			const method = 'GET';
			const url = '/api/v1/account/settings';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Get user setting
		 *
		 * @return {Promise<Object>} the resData object
		 */
		 getSetting: async function (key) {
			const method = 'GET';
			const url = '/api/v1/account/setting';

			const params = {
				key: key,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Set user setting
		 *
		 * @return {Promise<Object>} the resData object
		 */
		 setSetting: async function (key, data) {
			const method = 'POST';
			const url = '/api/v1/account/setting';

			const params = {
				key: key,
				data: data,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Add user favorite color
		 *
		 * @param {string} color
		 * @return {Promise<Object>} the resData object
		 */
		addColor: async function (color) {
			const method = 'POST';
			const url = '/api/v1/account/colors';
			const params = { color: color };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get Password Salt
		 *
		 * @param {boolean} [recover]
		 * @return {Promise<string>} the salt string
		 */
		getPasswordSalt: async function (recover) {
			const method = 'GET';
			const url = '/api/v1/account/get-password-salt';

			const params = {};
			if (recover) {
				params.recover = recover;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Set default language
		 *
		 * @param {string} language
		 * @return {Promise<Object>} the resData object
		 * */
		setDefaultLanguage: async function (language) {
			const method = 'PUT';
			const url = '/api/v1/account/default-language';

			const params = {
				language: language
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get user's keys
		 *
		 * @return {Promise<Object[]>} the keys
		 */
		getKeys: async function () {
			const method = 'GET';
			const url = '/api/v1/account/keys';

			const onSuccess = iurio.crypto.handler.onListAllKeys;

			return iurio.api.utils.sendRequest(method, url, onSuccess);
		},

		/**
		 * Update user's keys
		 *
		 * @param {Object[]} keys
		 */
		updateKeys: async function (keys) {
			const method = 'PUT';
			const url = '/api/v1/account/keys';

			const params = iurio.crypto.handler.beforeUpdateKeys(keys);

			return iurio.api.utils.sendRequest(method, url, params);
		},
	},

	/** @namespace */
	user: {
		/**
		 * Send a heartbeat
		 */
		heartbeat: async function () {
			const method = 'GET';
			const url = '/api/v1/users/heartbeat';

			try {
				return await iurio.api.utils.sendRequest(method, url);
			} catch (error) {
				console.error('heartbeat error', error);
				await iurio.crypto.keystore.removeKeyPair();
				if (window.location.pathname !== '/') {
					window.location = '/';
				}
			}
		},

		/**
		 * List user text notifications
		 *
		 * @param {boolean} [showRead]
		 * @return {Promise<Object>} the resData object
		 */
		listTextNotifications: async function (showRead) {
			const method = 'GET';
			const url = '/api/v1/users/text-notifications';

			const params = {};
			if (showRead !== undefined) {
				params.showRead = showRead;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Mark text notifications as read
		 *
		 * @param {number[]} notificationIDs
		 * @return {Promise<Object>} the resData object
		 */
		textNotificationsSeen: async function (notificationIDs) {
			const method = 'POST';
			const url = '/api/v1/users/text-notifications-seen';
			const params = { notificationIDs };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Remove (text and dot) notifications
		 *
		 * @param {number} [projectID]
		 * @param {number} [workspaceID]
		 * @return {Promise<Object>} the resData object
		 */
		removeNotifications: async function (projectID, workspaceID) {
			const method = 'DELETE';
			const url = '/api/v1/users/text-notifications';
			const params = {
				projectID,
				workspaceID,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},
	},

	/** @namespace */
	project: {
		/**
		 * List all projects
		 *
		 * @return {Promise<Object>} the resData object
		 */
		list: async function () {
			const method = 'GET';
			const url = '/api/v1/projects/list';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Get general data
		 *
		 * @return {Promise<Object>} the resData object
		 */
		getGeneralData: async function () {
			const method = 'GET';
			const url = '/api/v1/projects/get-general-data';
			const onSuccess = iurio.crypto.handler.onGetGeneralData;

			return iurio.api.utils.sendRequest(method, url, onSuccess);
		},

		/**
		 * List log entries
		 *
		 * @param {number} projectID
		 * @param {number} [userID]
		 * @param {number} [workspaceID]
		 * @param {(Date|number)} [startTime]
		 * @param {(Date|number)} [endTime]
		 * @return {Promise<Object[]>} the resData object
		 */
		listLog: async function (projectID, userID, workspaceID, startTime, endTime) {
			const method = 'GET';
			const url = '/api/v1/project/' + projectID + '/logging/list';

			const params = {};
			if (userID) {
				params.userID = userID;
			}
			if (workspaceID) {
				params.workspaceID = workspaceID;
			}
			if (startTime) {
				params.startTime = startTime instanceof Date ? startTime.getTime() : startTime;
			}
			if (endTime) {
				params.endTime = endTime instanceof Date ? endTime.getTime() : endTime;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Add a new project
		 *
		 * @param {string} name
		 * @param {Object[]} sysAdmins
		 * @param {number} [fromTemplateID]
		 * @param {boolean} [isTemplate]
		 * @param {boolean} [isIurioTemplate]
		 * @param {boolean} [templateKey]
		 * @return {Promise<Object>} the resData object
		 */
		add: async function (name, sysAdmins, fromTemplateID, isTemplate, isIurioTemplate, templateKey) {
			if (isTemplate && !templateKey) {
				throw 'Invalid arguments for iurio.api.project.add - missing templateKey';
			}

			const method = 'POST';
			const url = '/api/v1/projects/add';

			let keyData = {
				wrappedKey: 'unused',
				sysAdminKeys: [],
				key: templateKey,
			};

			if (!isTemplate) {
				keyData = await iurio.crypto.handler.beforeProjectAdd(sysAdmins);
			}

			const params = {
				projectname: name,
				encryptedSymmetricKey: keyData.wrappedKey,
				sysAdminKeys: keyData.sysAdminKeys,
				isTemplate,
				isIurioTemplate,
				fromTemplateID,
			};

			const onSuccess = project => iurio.crypto.handler.onProjectAdd(project, keyData.key);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Remove a project
		 *
		 * @param {number} projectID
		 * @return {Promise<Object>} the resData object
		 */
		remove: async function (projectID) {
			const method = 'DELETE';
			const url = '/api/v1/project/' + projectID;

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Rename a project
		 *
		 * @param {number} projectID
		 * @param {string} name
		 * @return {Promise<Object>} the resData object
		 */
		rename: async function (projectID, name) {
			const method = 'PUT';
			const url = '/api/v1/project/' + projectID + '/name';
			const params = { projectName: name };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Archive a project
		 *
		 * @param {number} projectID
		 * @param {boolean} isArchived
		 * @return {Promise<Object>} the resData object
		 */
		archive: async function (projectID, isArchived) {
			const method = 'PUT';
			const url = '/api/v1/project/' + projectID + '/archive';
			const params = { isArchived };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * List participants
		 *
		 * @param {number} projectID
		 * @param {number} [workspaceID]
		 * @return {Promise<Object>} the resData object
		 */
		listParticipants: async function (projectID, workspaceID) {
			const method = 'GET';
			const url = '/api/v1/project/' + projectID + (workspaceID ? '/workspace/' + workspaceID : '') + '/participants';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Add participants
		 *
		 * @param {Object} project
		 * @param {number} project.id
		 * @param {CryptoKey} project.symmetricKey
		 * @param {Object[]} users
		 * @param {number} users.id
		 * @param {string} users.publicKey
		 * @param {number[]} roleIDList
		 * @return {Promise<Object>} the resData object
		 */
		addParticipants: async function (project, users, roleIDList) {
			if (users.length === 0) {
				return;
			}

			const method = 'POST';
			const url = '/api/v1/project/' + project.id + '/participants';

			const keys = await iurio.crypto.handler.beforeProjectParticipantAdd(project, users);
			const userIDs = users.map(user => user.id);
			const params = {
				userIDs: userIDs,
				generalWorkspaceEncryptedSymmetricKeys: keys,
				roleIDList,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Remove participants
		 *
		 * @param {number} projectID
		 * @param {number[]} userIDs
		 * @return {Promise<Object>} the resData object
		 */
		removeParticipants: async function (projectID, userIDs) {
			if (userIDs.length === 0) {
				return;
			}

			const method = 'DELETE';
			const url = '/api/v1/project/' + projectID + '/participants';
			const params = { userIDs };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Edit participant
		 *
		 * @param {number} projectID
		 * @param {number} userID
		 * @param {number} roleID
		 * @return {Promise<Object>} the resData object
		 */
		editParticipant: async function (projectID, userID, roleID) {
			const method = 'PATCH';
			const url = '/api/v1/project/' + projectID + '/participants/' + userID;
			const params = { roleID };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/** @namespace */
		workspace: {
			/**
			 * List workspaces in given project
			 *
			 * @param {number} projectID
			 * @return {Promise<Object>} the resData object
			 */
			list: async function (projectID) {
				const method = 'GET';
				const url = '/api/v1/project/' + projectID + '/workspaces/list';

				return iurio.api.utils.sendRequest(method, url);
			},

			/**
			 * Add a workspace
			 *
			 * @param {number} projectID
			 * @param {string} name
			 * @param {boolean} [isExternal]
			 * @param {boolean} [hasDatasafe]
			 * @param {boolean} [hasBoard]
			 * @param {boolean} [hasChat]
			 * @param {Object[]} sysAdmins
			 * @param {number} sysAdmins.id
			 * @param {string} sysAdmins.publicKey
			 * @param {Object} [templateKey]
			 * @param {number} templateKey.id
			 * @param {CryptoKey} templateKey.symmetricKey
			 * @return {Promise<Object>} the resData object
			 */
			addWorkspace: async function (projectID, name, isExternal, hasDatasafe, hasBoard, hasChat, sysAdmins, templateKey) {
				const method = 'POST';
				const url = '/api/v1/project/' + projectID + '/workspace';

				const workspaceData = await iurio.crypto.handler.beforeWorkspaceAdd(name, sysAdmins, templateKey);
				const params = {
					name: workspaceData.name,
					encryptedSymmetricKey: workspaceData.wrappedKey,
					sysAdminKeys: workspaceData.sysAdminKeys,
					isTemplateProject: !!templateKey,
				};
				if (isExternal !== undefined) {
					params.isExternal = isExternal;
				}
				if (hasDatasafe !== undefined) {
					params.hasDatasafe = hasDatasafe;
				}
				if (hasBoard !== undefined) {
					params.hasBoard = hasBoard;
				}
				if (hasChat !== undefined) {
					params.hasChat = hasChat;
				}

				const onSuccess = workspace => iurio.crypto.handler.onWorkspaceAdd(workspace, workspaceData.key, name);

				return iurio.api.utils.sendRequest(method, url, params, onSuccess);
			},

			/**
			 * Remove a workspace
			 *
			 * @param {number} projectID
			 * @param {number} workspaceID
			 * @return {Promise<Object>} the resData object
			 */
			removeWorkspace: async function (projectID, workspaceID) {
				const method = 'DELETE';
				const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID;

				return iurio.api.utils.sendRequest(method, url);
			},

			/**
			 * Manage a workspace
			 *
			 * @param {Object} workspace
			 * @param {string} [name]
			 * @param {boolean} hasDatasafe
			 * @param {boolean} hasBoard
			 * @param {boolean} hasChat
			 * @param {boolean} isArchived
			 * @return {Promise<Object>} the resData object
			 */
			manageWorkspace: async function (workspace, name, hasDatasafe, hasBoard, hasChat, isArchived) {
				const method = 'PUT';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/manage';

				const encryptedName = await iurio.crypto.handler.encryptName(name, workspace);
				const params = {
					workspaceName: encryptedName,
					hasDatasafe: hasDatasafe,
					hasBoard: hasBoard,
					hasChat: hasChat,
					isArchived: isArchived,
				};

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Rename a workspace
			 *
			 * @param {Object} workspace
			 * @param {string} name
			 * @return {Promise<Object>} the resData object
			 */
			renameWorkspace: async function (workspace, name) {
				const method = 'PUT';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/name';

				const encryptedName = await iurio.crypto.handler.encryptName(name, workspace);
				const params = { workspaceName: encryptedName };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Add workspace participants
			 *
			 * @param {Object} project
			 * @param {number} project.id
			 * @param {CryptoKey} project.symmetricKey
			 * @param {Object} workspace
			 * @param {number} workspace.id
			 * @param {CryptoKey} workspace.symmetricKey
			 * @param {Object[]} users
			 * @param {number} users.id
			 * @param {string} users.publicKey
			 * @param {number[]} roleIDs
			 * @return {Promise<Object>} the resData object
			 */
			addWorkspaceParticipants: async function (project, workspace, users, roleIDs) {
				if (users.length === 0) {
					return;
				}

				const method = 'POST';
				const url = '/api/v1/project/' + project.id + '/workspace/' + workspace.id + '/participants';

				const keys = await iurio.crypto.handler.beforeWorkspaceParticipantAdd(workspace, project, users);
				const userIDs = users.map(function (user) { return user.id; });
				const params = {
					userIDs: userIDs,
					encryptedSymmetricKeys: keys.wrappedKeys,
					generalWorkspaceEncryptedSymmetricKeys: keys.wrappedGeneralKeys,
				};
				if (roleIDs) {
					params.roleIDs = roleIDs;
				}

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Remove workspace participants
			 *
			 * @param {number} projectID
			 * @param {number} workspaceID
			 * @param {number[]} userIDs
			 * @return {Promise<Object>} the resData object
			 */
			removeWorkspaceParticipants: async function (projectID, workspaceID, userIDs) {
				if (userIDs.length === 0) {
					return;
				}

				const method = 'DELETE';
				const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/participants';
				const params = { userIDs };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Edit workspace participans permissions
			 *
			 * @param {number} projectID
			 * @param {number} workspaceID
			 * @param {number} userID
			 * @param {Object} permissions
			 * @return {Promise<Object>} the resData object
			 */
			editWorkspaceParticipant: async function (projectID, workspaceID, userID, permissions) {
				const method = 'PUT';
				const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/participant/' + userID + '/permissions';
				const params = { permissions };

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * Invite external users
			 *
			 * @param {Object[]} workspaces
			 * @param {string[]} emailAddresses
			 * @param {Object[]} allUsers
			 * @param {string} emailText
			 * @param {number[]} [roleIDs]
			 * @param {string[]} [names]
			 * @param {Boolean} [resend]
			 * @param {Boolean} [noMail]
			 * @return {Promise<Object>} the resData object
			 */
			inviteExternalUsers: async function (workspaces, emailAddresses, allUsers, emailText, roleIDs, names, resend, noMail) {
				if (workspaces.length === 0 || emailAddresses.length === 0) {
					return;
				}

				const method = 'POST';
				const url = '/api/v1/project/' + workspaces[0].project + '/invite';

				const keyData = await iurio.crypto.handler.beforeInviteUsers(emailAddresses, allUsers, workspaces, undefined);
				const workspaceIDs = workspaces.map(workspace => workspace.id);
				const params = {
					workspaceIDs: workspaceIDs,
					emailAddresses: emailAddresses,
					privKeys: keyData.privKeys,
					pubKeys: keyData.pubKeys,
					encryptedSymmetricKeys: keyData.wrappedWorkspaceKeys,
				};
				if (roleIDs) {
					params.roleIDs = roleIDs;
				}
				if (names) {
					params.names = names;
				}
				if (resend) {
					params.resend = resend;
				}
				if (noMail) {
					params.noMail = noMail;
				}

				const onSuccess = async function (users) {
					for (let user of users) {
						const keyIndex = emailAddresses.findIndex(function (email) { return email.toLowerCase() === user.email.toLowerCase(); });
						const key = keyData.keys[keyIndex];
						const link = user.link.replace(/__KEY__/g, key);

						if (noMail) {
							user.link = link;
							continue;
						}

						const variables = {
							'invite_text': emailText,
							'invite_link': link,
						};
						try {
							await iurio.api.utils.sendMailTemplate(user.emailToken, user.subject, user.name, variables);
						} catch (err) {
							console.error(err);
						}
					}
					return users;
				};

				return iurio.api.utils.sendRequest(method, url, params, onSuccess);
			},

			/**
			 * List tasks with attachment
			 *
			 * @param {Object} workspace
			 * @return {Promise<Object>} the resData object
			 */
			listTasksWithAttachment: async function (workspace) {
				const method = 'GET';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/list-tasks-with-attachments';

				const onSuccess = resData => iurio.crypto.handler.onListTasksWithAttachment(resData, workspace);

				return iurio.api.utils.sendRequest(method, url, onSuccess);
			},

			/**
			 * List boardlanes
			 *
			 * @param {Object} workspace
			 * @return {Promise<Object>} the resData object
			 */
			listBoardLanes: async function (workspace) {
				const method = 'GET';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/list-boardlanes';

				return iurio.api.utils.sendRequest(method, url);
			},

			/** @namespace */
			board: {
				/**
				 * Get board data
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object>} the resData object
				 */
				getBoardData: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/get-data';

					const onSuccess = resData => iurio.crypto.handler.onGetBoardData(resData, workspace);

					return iurio.api.utils.sendRequest(method, url, onSuccess);
				},

				/**
				 * Edit board lanes
				 *
				 * @param {number} projectID
				 * @param {number} workspaceID
				 * @param {Object[]} lanes
				 * @return {Promise<Object>} the resData object
				 */
				editBoardLanes: async function (projectID, workspaceID, lanes) {
					const method = 'POST';
					const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/board/lanes/edit';
					const params = { lanes };

					return iurio.api.utils.sendRequest(method, url, params);
				},
			},

			/** @namespace */
			tags: {
				/**
				 * List tags in given workspace
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object[]>} the resData object as tag array
				 */
				list: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tags';

					const onSuccess = resData => iurio.crypto.handler.onListTags(resData, workspace);

					return iurio.api.utils.sendRequest(method, url, onSuccess);
				},

				/**
				 * Create tag in given workspace
				 *
				 * @param {Object} workspace
				 * @param {string} name
				 * @param {string} color
				 * @return {Promise<Object>} the created tag
				 */
				add: async function (workspace, name, color) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tags';
					const params = {
						name: await iurio.crypto.handler.encryptName(name, workspace),
						color: color,
					};

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Remove tag in given workspace
				 *
				 * @param {Object} workspace
				 * @param {number} tagID
				 * @return {Promise<Object>} the removed tag
				 */
				remove: async function (workspace, tagID) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tags';
					const params = { tagID };

					return iurio.api.utils.sendRequest(method, url, params);
				},


				/**
				 * Edit tag in given workspace
				 *
				 * @param {Object} workspace
				 * @param {number} tagID
				 * @param {string} [name]
				 * @param {string} [color]
				 * @return {Promise<Object>} the edited tag
				 */
				edit: async function (workspace, tagID, name, color) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tags';

					const params = { tagID };
					if (name) {
						params.name = await iurio.crypto.handler.encryptName(name, workspace);
					}
					if (color) {
						params.color = color;
					}

					const onSuccess = tag => {
						if (name) {
							tag.name = name;
						}
						return tag;
					};

					return iurio.api.utils.sendRequest(method, url, params, onSuccess);
				},

				/**
				 * List file tags in given workspace
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object[]>} the resData object as tag array
				 */
				listFileTags: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/file-tags';

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * List task tags in given workspace
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object[]>} the resData object as tag array
				 */
				listTaskTags: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/task-tags';

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Attach tag to given files
				 *
				 * @param {Object} workspace
				 * @param {number} tagID
				 * @param {number[]} fileIDs file IDs to attach the tag to
				 * @return {Promise<Object[]>} the attached tags
				 */
				attachTagToFiles: function (workspace, tagID, fileIDs) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tag/' + tagID;
					const params = { fileIDs };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Detach tag from given files
				 *
				 * @param {Object} workspace
				 * @param {number} tagID
				 * @param {number[]} fileIDs file IDs to detach the tag from
				 * @return {Promise<Object[]>} the detached tags
				 */
				detachTagFromFiles: function (workspace, tagID, fileIDs) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tag/' + tagID;
					const params = { fileIDs };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Attach tag to given tasks
				 *
				 * @param {Object} workspace
				 * @param {number} tagID
				 * @param {number[]} taskIDs task IDs to attach the tag to
				 * @return {Promise<Object[]>} the attached tags
				 */
				attachTagToTasks: function (workspace, tagID, taskIDs) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tag/' + tagID;
					const params = { taskIDs };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Detach tag from given tasks
				 *
				 * @param {Object} workspace
				 * @param {number} tagID
				 * @param {number[]} taskIDs task IDs to detach the tag from
				 * @return {Promise<Object[]>} the detached tags
				 */
				detachTagFromTasks: function (workspace, tagID, taskIDs) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/tag/' + tagID;
					const params = { taskIDs };

					return iurio.api.utils.sendRequest(method, url, params);
				},
			},

			/** @namespace */
			reminder: {
				/**
				 * List reminders in workspace/project
				 *
				 * @param {Object} workspaceOrProject
				 * @return {Promise<Object>} the resData object
				 */
				list: async function (workspaceOrProject) {
					const method = 'GET';
					const url = '/api/v1/project/' +
						(workspaceOrProject.workspaces ? workspaceOrProject.id : workspaceOrProject.project + '/workspace/' + workspaceOrProject.id) + '/reminder';

					const onSuccess = resData => iurio.crypto.handler.onListWorkspaceReminder(resData);

					return iurio.api.utils.sendRequest(method, url, onSuccess);
				},

				/**
				 * Add a reminder
				 *
				 * @param {Object} workspace
				 * @param {string} name
				 * @param {Date} dueDate
				 * @param {string} message
				 * @param {number[]} userIDs
				 * @param {Date[]} notificationDates
				 * @param {number} recurrence
				 * @param {number} [recurrenceCount]
				 * @param {Date} [recurrenceEnds]
				 * @param {Boolean} [confirmationEnabled]
				 * @param {Boolean} [individualConfirmations]
				 * @return {Promise<Object>} the resData object
				 */
				add: async function (workspace, name, dueDate, message, userIDs, notificationDates, recurrence, recurrenceCount, recurrenceEnds, confirmationEnabled, individualConfirmations) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/reminder';

					const params = {
						name,
						dueDate,
						message,
						userIds: userIDs,
						notificationDates,
						recurrence,
						confirmationEnabled,
						individualConfirmations,
					};
					if (recurrenceCount) {
						params.recurrenceCount = recurrenceCount;
					}
					if (recurrenceEnds) {
						params.recurrenceEnds = recurrenceEnds;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Edit a reminder
				 *
				 * @param {Object} workspace
				 * @param {number} reminderID
				 * @param {string} name
				 * @param {Date} dueDate
				 * @param {string} message
				 * @param {number[]} userIDs
				 * @param {Date[]} notificationDates
				 * @param {number} recurrence
				 * @param {number} [recurrenceCount]
				 * @param {Date} [recurrenceEnds]
				 * @param {Boolean} [confirmationEnabled]
				 * @param {Boolean} [individualConfirmations]
				 * @return {Promise<Object>} the resData object
				 */
				edit: async function (workspace, reminderID, name, dueDate, message, userIDs, notificationDates, recurrence, recurrenceCount, recurrenceEnds, confirmationEnabled, individualConfirmations) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/reminder/' + reminderID;

					const params = {
						name,
						dueDate,
						message,
						userIds: userIDs,
						notificationDates,
						recurrence,
						confirmationEnabled,
						individualConfirmations,
					};
					if (recurrenceCount) {
						params.recurrenceCount = recurrenceCount;
					}
					if (recurrenceEnds) {
						params.recurrenceEnds = recurrenceEnds;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Set reminder status
				 *
				 * @param {Object} workspace
				 * @param {number} reminderID
				 * @param {number} status
				 * @return {Promise<Object>} the resData object
				 */
				setStatus: async function (workspace, reminderID, status) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/reminder/' + reminderID + '/status';

					const params = { status };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Remove a reminder
				 *
				 * @param {Object} workspace
				 * @param {number} reminderID
				 * @return {Promise<Object>} the resData object
				 */
				remove: async function (workspace, reminderID) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/reminder/' + reminderID;

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Confirm a reminder
				 *
				 * @param {Object} workspace
				 * @param {number} reminderID
				 * @param {string} [comment]
				 * @return {Promise<Object>} the resData object
				 */
				confirm: async function (workspace, reminderID, comment) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/reminder/' + reminderID + '/confirm';

					const params = {};
					if (comment) {
						params.comment = comment;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},
			},

			/** @namespace */
			meeting: {
				/**
				 * Get meeting room token for given workspace
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object>} the resData object
				 */
				getRoomToken: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/meeting';

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Get meeting room token for given workspace
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object>} the resData object
				 */
				closeRoom: async function (workspace) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/meeting';

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Subscribe to meeting room updates
				 *
				 * @param {String} meetingToken
				 * @return {Promise<Object>} the resData object
				 */
				subscribe: async function (meetingToken) {
					const method = 'POST';
					const url = '/api/v1/meeting/' + meetingToken + '/subscribe';

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Ask for access to a meeting room
				 *
				 * @param {String} meetingToken
				 * @param {String} name
				 * @return {Promise<Object>} the resData object
				 */
				knock: async function (meetingToken, name) {
					const method = 'POST';
					const url = '/api/v1/meeting/' + meetingToken + '/knock';
					const params = { name };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * List meeting room waiting persons
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object>} the resData object
				 */
				listGuests: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/meeting/guest';

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Admit waiting person to a meeting room
				 *
				 * @param {Object} workspace
				 * @param {string} guestToken
				 * @param {string} name
				 * @param {string} socketID
				 * @param {string} sessionID
				 * @return {Promise<Object>} the resData object
				 */
				admit: async function (workspace, guestToken, name, socketID, sessionID) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/meeting/admit';
					const params = {
						guestToken,
						name,
						socketID,
						sessionID,
					};

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Remove waiting person from a meeting room
				 *
				 * @param {Object} workspace
				 * @param {string} sessionID
				 * @return {Promise<Object>} the resData object
				 */
				removeGuest: async function (workspace, sessionID) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/meeting/guest';
					const params = { sessionID };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Start a meeting room verification
				 *
				 * @param {Object} workspace
				 * @param {string} emailAddress
				 * @param {string} participantID
				 * @return {Promise<Object>} the resData object
				 */
				startVerification: async function (workspace, emailAddress, participantID) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/meeting/start-verification';
					const params = {
						emailAddress,
						participantID,
					};

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Verify a video meeting member
				 *
				 * @param {string} meetingToken
				 * @param {string} verificationToken
				 * @param {number} code
				 * @return {Promise<Object>} the resData object
				 */
				verify: async function (meetingToken, verificationToken, code) {
					const method = 'POST';
					const url = '/api/v1/meeting/' + meetingToken + '/verify/' + verificationToken;
					const params = { code };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Get a screen share key
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object>} the resData object
				 */
				getScreenShareKey: async function (workspace) {
					const method = 'GET';
					const url = `/api/v1/project/${workspace.project}/workspace/${workspace.id}/meeting/screen`;
					return iurio.api.utils.sendRequest(method, url);
				},
			},

			/** @namespace */
			approval: {
				/**
				 * Add an approval
				 *
				 * @param {Object} workspace
				 * @param {number[]} userIDs
				 * @param {string} comment
				 * @param {Object} [task]
				 * @return {Promise<Object>} the resData object
				 */
				add: async function (workspace, userIDs, comment, task) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/approval';
					const encryptedText = await iurio.crypto.handler.encryptName(comment, workspace);

					const params = {
						comment: encryptedText,
						userIDs: userIDs,
					};
					if (task) {
						params.taskID = task.id;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * List approvals in workspace
				 *
				 * @param {Object} workspace
				 * @return {Promise<Object>} the resData object
				 */
				list: async function (workspace) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/approval';

					const onSuccess = resData => iurio.crypto.handler.onListWorkspaceApproval(resData, workspace);

					return iurio.api.utils.sendRequest(method, url, onSuccess);
				},

				/**
				 * Vote on an approval
				 *
				 * @param {Object} workspace
				 * @param {Number} approvalID
				 * @param {Boolean} accepted
				 * @param {string} comment
				 * @return {Promise<Object>} the resData object
				 */
				vote: async function (workspace, approvalID, accepted, comment) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/approval/' + approvalID + '/vote';

					const encryptedText = await iurio.crypto.handler.encryptName(comment, workspace);
					const params = {
						accepted: accepted,
						comment: encryptedText,
					};

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Finish an approval
				 *
				 * @param {Object} workspace
				 * @param {Number} approvalID
				 * @return {Promise<Object>} the resData object
				 */
				finish: async function (workspace, approvalID) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/approval/' + approvalID + '/finish';

					return iurio.api.utils.sendRequest(method, url);
				},
			},

			/** @namespace */
			datasafe: {
				/**
				 * Download a file
				 *
				 * @param {Object} workspace
				 * @param {Object} file
				 * @param {number} file.id
				 * @param {string} file.name
				 * @param {string} file.encryptedSymmetricKey
				 * @param {Object} [handlers]
				 * @param {number} [versionID]
				 * @param {Object} [versionWorkspace]
				 * @return {Promise<File>} the resData object
				 */
				downloadFile: async function (workspace, file, handlers, versionID, versionWorkspace) {
					const method = 'GET';
					let url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + file.id;
					if (versionID) {
						url += '/version/' + versionID;
					}
					const xhr = new XMLHttpRequest();
					return new Promise(function (resolve, reject) {
						xhr.open(method, url, true);
						xhr.responseType = 'blob';
						xhr.onload = async function () {
							file.encryptedSymmetricKey = xhr.getResponseHeader('x-iurio-encryptedSymmetricKey');
							file.lastModified = xhr.getResponseHeader('x-iurio-lastModified');
							const downloaded = iurio.crypto.handler.onDownload(this.response, versionWorkspace ? versionWorkspace : workspace, file);
							resolve(downloaded);
						};
						if (handlers) {
							if (handlers.onProgress) {
								xhr.onprogress = handlers.onProgress;
							}
							if (handlers.onAbort) {
								xhr.onabort = handlers.onAbort;
							}
						}
						xhr.onerror = reject;
						xhr.send();
					});
				},

				/**
				 * Upload a file
				 *
				 * @param {Object} workspace
				 * @param {Object} file
				 * @param {number} targetFolderID
				 * @param {Object} [fileToUpdate]
				 * @param {Object} [handlers]
				 * @return {Promise<File>} the resData object
				 */
				uploadFile: async function (workspace, file, targetFolderID, fileToUpdate, handlers) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/folder/' + targetFolderID;
					const filename = file.name;
					const encryptedFile = await iurio.crypto.handler.beforeUpload(file, workspace, fileToUpdate);
					const params = new FormData();
					if (iurioData.csrf) {
						params.append('_csrf', iurioData.csrf);
					}
					params.append('encryptedSymmetricKeys', encryptedFile.upload.symmetricKey);
					params.append('sizes', [encryptedFile.upload.originalSize]);
					params.append('fileNames', [encryptedFile.upload.filename]);
					params.append('lastModifieds', [file.lastModified]);
					if (fileToUpdate) {
						params.append('fileIDsToUpdate', [fileToUpdate.id]);
						params.append('hashes', [encryptedFile.upload.hash]);
					}
					if (file.versionDescription) {
						params.append('versionDescriptions', [encryptedFile.upload.versionDescription]);
					}
					params.append('file', encryptedFile);

					const xhr = new XMLHttpRequest();
					return new Promise(function (resolve, reject) {
						xhr.open(method, url, true);

						xhr.responseType = 'json';
						xhr.onload = async function () {
							if (this.status !== 200 || !this.response) {
								return reject({
									error: true,
									statusCode: this.status,
									body: this.statusText,
								});
							}
							if (this.response.length !== 1) {
								return reject(this.response);
							}
							const file = this.response[0];
							file.name = filename;
							resolve(file);
						};
						if (handlers) {
							if (handlers.onProgress) {
								xhr.upload.onprogress = handlers.onProgress;
							}
							if (handlers.onAbort) {
								xhr.upload.onabort = handlers.onAbort;
							}
						}
						xhr.onerror = reject;
						xhr.send(params);
					});
				},

				/**
				 * Check before uploading a file
				 *
				 * @param {Object} workspace
				 * @param {Object} file
				 * @param {number} targetFolderID
				 * @param {Object} [fileToUpdate]
				 * @return {Promise<File>} the resData object
				 */
				uploadFileCheck: async function (workspace, file, targetFolderID, fileToUpdate) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/folder/' + targetFolderID + '/check';

					const params = {
						size: file.size,
						fileName: await iurio.crypto.handler.encryptName(file.renamedFileName ? file.renamedFileName : file.name, workspace),
					};
					if (fileToUpdate) {
						params.fileIDToUpdate = fileToUpdate.id;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Set file description
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {string} description
				 * @return {Promise<Object>} the resData object
				 */
				setFileDescription: async function (workspace, fileID, description) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/description';

					const encryptedDescription = await iurio.crypto.handler.encryptName(description, workspace);
					const params = { description: encryptedDescription };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Set file version description
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {number} versionID
				 * @param {string} description
				 * @param {Object} [versionWorkspace] the workspace the given version is in (current is taken if omitted)
				 * @return {Promise<Object>} the resData object
				 */
				setVersionDescription: async function (workspace, fileID, versionID, description, versionWorkspace) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/version/' + versionID + '/description';

					const encryptedDescription = await iurio.crypto.handler.encryptName(description, versionWorkspace ? versionWorkspace : workspace);
					const params = { description: encryptedDescription };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Add file version comment
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {number} versionID
				 * @param {string} comment
				 * @param {Object} [versionWorkspace] the workspace the given version is in (current is taken if omitted)
				 * @param {number} [replyToCommentID] comment id this comment replies to
				 * @return {Promise<Object>} the resData object
				 */
				addVersionComment: async function (workspace, fileID, versionID, comment, versionWorkspace, replyToCommentID) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/version/' + versionID + '/comment';

					const encryptedComment = await iurio.crypto.handler.encryptName(comment, versionWorkspace ? versionWorkspace : workspace);
					const params = { comment: encryptedComment };
					if (replyToCommentID) {
						params.replyToCommentID = replyToCommentID;
					}

					const onSuccess = function (result) {
						result.comment = comment;
						return result;
					};

					return iurio.api.utils.sendRequest(method, url, params, onSuccess);
				},

				/**
				 * Edit own file version comment
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {number} versionID
				 * @param {number} commentID
				 * @param {string} comment
				 * @param {Object} [versionWorkspace] the workspace the given version is in (current is taken if omitted)
				 * @return {Promise<Object>} the resData object
				 */
				editVersionComment: async function (workspace, fileID, versionID, commentID, comment, versionWorkspace) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/version/' + versionID + '/comment/' + commentID;

					const encryptedComment = await iurio.crypto.handler.encryptName(comment, versionWorkspace ? versionWorkspace : workspace);
					const params = { comment: encryptedComment };

					const onSuccess = function (result) {
						result.comment = comment;
						return result;
					};

					return iurio.api.utils.sendRequest(method, url, params, onSuccess);
				},

				/**
				 * Remove own file version comment
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {number} versionID
				 * @param {number} commentID
				 * @return {Promise<Object>} the resData object
				 */
				removeVersionComment: async function (workspace, fileID, versionID, commentID) {
					const method = 'DELETE';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/version/' + versionID + '/comment/' + commentID;

					return iurio.api.utils.sendRequest(method, url);
				},

				/**
				 * Rename a file
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {string} fileName
				 * @return {Promise<Object>} the resData object
				 */
				renameFile: async function (workspace, fileID, fileName) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/rename';

					const encryptedName = await iurio.crypto.handler.encryptName(fileName, workspace);
					const params = { fileName: encryptedName };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Add a folder
				 *
				 * @param {Object} workspace
				 * @param {number} parentFolder
				 * @param {string} folderName
				 * @return {Promise<Object>} the resData object
				 */
				addFolder: async function (workspace, parentFolder, folderName) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/folder';

					const encryptedName = await iurio.crypto.handler.encryptName(folderName, workspace);
					const params = {
						parentFolder: parentFolder,
						folderName: encryptedName,
					};

					const onSuccess = function (folder) {
						folder.name = folderName;
						return folder;
					};

					return iurio.api.utils.sendRequest(method, url, params, onSuccess);
				},

				/**
				 * Rename a folder
				 *
				 * @param {Object} workspace
				 * @param {number} folderID
				 * @param {string} folderName
				 * @return {Promise<Object>} the resData object
				 */
				renameFolder: async function (workspace, folderID, folderName) {
					const method = 'PUT';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/folder/' + folderID + '/rename';

					const encryptedName = await iurio.crypto.handler.encryptName(folderName, workspace);
					const params = { folderName: encryptedName };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Remove selected files and folders
				 *
				 * @param {Object} workspace
				 * @param {Object[]} files
				 * @param {Object[]} folders
				 * @return {Promise<Object>} the resData object
				 */
				removeSelected: async function (workspace, files, folders) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/remove';

					let parentFolderID;
					if (folders.length > 0) {
						parentFolderID = folders[0].parentFolderID;
					} else {
						if (files.length > 0) {
							parentFolderID = files[0].folder;
						} else {
							return;
						}
					}
					const removeContent = {
						id: parentFolderID,
						subFolders: folders,
						files: files,
					};
					const params = { removeContent };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Copy selected files and folders
				 *
				 * @param {Object} workspace
				 * @param {Object[]} files
				 * @param {Object[]} folders
				 * @param {number} [parentFolderID]
				 * @param {Object} [targetWorkspace]
				 * @param {boolean} [copyHistory]
				 * @param {boolean} [keepVersionDescriptions]
				 * @param {boolean} [keepVersionComments]
				 * @param {Object[]} [tagMapping]
				 * @return {Promise<Object>} the resData object
				 */
				copySelected: async function (workspace, files, folders, parentFolderID, targetWorkspace, copyHistory, keepVersionDescriptions, keepVersionComments, tagMapping) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/copy';

					let sourceParentFolderID;
					if (folders.length > 0) {
						sourceParentFolderID = folders[0].parentFolderID;
					} else {
						if (files.length > 0) {
							sourceParentFolderID = files[0].folder;
						} else {
							return;
						}
					}
					let copyContent = {
						id: sourceParentFolderID,
						subFolders: folders,
						files: files,
					};
					copyContent = await iurio.crypto.handler.beforeCopyMoveFiles(copyContent, workspace, targetWorkspace);
					const params = { copyContent };
					if (parentFolderID) {
						params.parentFolderID = parentFolderID;
					}
					if (targetWorkspace) {
						params.targetWorkspaceID = targetWorkspace.id;
					}
					if (copyHistory) {
						params.copyHistory = true;
						if (keepVersionDescriptions) {
							params.keepVersionDescriptions = true;
						}
						if (keepVersionComments) {
							params.keepVersionComments = true;
						}
					}
					if (tagMapping) {
						if (!Array.isArray(tagMapping)) {
							throw 'tagMapping is not an array';
						} else if (tagMapping.length > 0) {
							params.tagMapping = tagMapping;
						}
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Move selected files and folders
				 *
				 * @param {Object} workspace
				 * @param {Object[]} files
				 * @param {Object[]} folders
				 * @param {number} [parentFolderID]
				 * @param {Object} [targetWorkspace]
				 * @param {Object[]} [tagMapping]
				 * @return {Promise<Object>} the resData object
				 */
				moveSelected: async function (workspace, files, folders, parentFolderID, targetWorkspace, tagMapping) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/move';

					let sourceParentFolderID;
					if (folders.length > 0) {
						sourceParentFolderID = folders[0].parentFolderID;
					} else {
						if (files.length > 0) {
							sourceParentFolderID = files[0].folder;
						} else {
							return;
						}
					}
					let moveContent = {
						id: sourceParentFolderID,
						subFolders: folders,
						files: files,
					};
					moveContent = await iurio.crypto.handler.beforeCopyMoveFiles(moveContent, workspace, targetWorkspace);
					const params = { moveContent };
					if (parentFolderID) {
						params.parentFolderID = parentFolderID;
					}
					if (targetWorkspace) {
						params.targetWorkspaceID = targetWorkspace.id;
					}
					if (tagMapping) {
						if (!Array.isArray(tagMapping)) {
							throw 'tagMapping is not an array';
						} else if (tagMapping.length === 0) {
							throw 'tagMapping is an empty array';
						}
						params.tagMapping = tagMapping;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Copy datasafe file version and make it current
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {Object} version
				 * @param {Object} [versionWorkspace] the workspace the given version is in (not required if same)
				 * @return {Promise<Object>} the resData object
				 */
				copyVersionToCurrent: async function (workspace, fileID, version, versionWorkspace) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/version/' + version.id + '/copy';

					const encryptedSymmetricKey = await iurio.crypto.handler.beforeCopyDatasafeFileVersion(workspace, version, versionWorkspace);
					const params = {};
					if (encryptedSymmetricKey) {
						params.encryptedSymmetricKey = encryptedSymmetricKey;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Export datasafe file version to a new file
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {Object} version
				 * @param {string} targetName
				 * @param {number} [parentFolderID]
				 * @param {Object} [targetWorkspace]
				 * @param {Object} [versionWorkspace] the workspace the given version is in (not required if same)
				 * @param {boolean} [copyHistory]
				 * @param {boolean} [keepVersionDescriptions]
				 * @param {boolean} [keepVersionComments]
				 * @return {Promise<Object>} the resData object
				 */
				exportVersion: async function (workspace, fileID, version, targetName, parentFolderID, targetWorkspace, versionWorkspace, copyHistory, keepVersionDescriptions, keepVersionComments) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/version/' + version.id + '/export';

					const data = await iurio.crypto.handler.beforeExportDatasafeFileVersion(targetName, targetWorkspace ? targetWorkspace : workspace, version, versionWorkspace);
					const params = { targetName: data.name };
					if (parentFolderID) {
						params.parentFolderID = parentFolderID;
					}
					if (targetWorkspace) {
						params.targetWorkspaceID = targetWorkspace.id;
					}
					if (data.encryptedSymmetricKey) {
						params.encryptedSymmetricKey = data.encryptedSymmetricKey;
					}
					if (copyHistory) {
						params.copyHistory = true;
						if (keepVersionDescriptions) {
							params.keepVersionDescriptions = true;
						}
						if (keepVersionComments) {
							params.keepVersionComments = true;
						}
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * List datasafe contents
				 *
				 * @param {Object} workspace
				 * @param {boolean} [subscribeToChanges]
				 * @return {Promise<Object>} the resData object
				 */
				listContents: async function (workspace, subscribeToChanges) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/list';

					const params = {};
					if (subscribeToChanges) {
						params.subscribeToChanges = true;
					}

					const onSuccess = resData => iurio.crypto.handler.onListDatasafeContents(resData, workspace);

					return iurio.api.utils.sendRequest(method, url, params, onSuccess);
				},

				/**
				 * List datasafe file history
				 *
				 * @param {Object} workspace
				 * @param {number} fileID
				 * @param {Object[]} workspaces All workspaces of the current user
				 * @return {Promise<Object>} the resData object
				 */
				listFileHistory: async function (workspace, fileID, workspaces) {
					const method = 'GET';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + fileID + '/history';

					const onSuccess = resData => iurio.crypto.handler.onListDatasafeFileHistory(resData, workspaces);

					return iurio.api.utils.sendRequest(method, url, onSuccess);
				},

				/**
				 * Mark files as seen
				 *
				 * @param {number} projectID
				 * @param {number} workspaceID
				 * @param {number[]} fileIDs
				 * @return {Promise<Object>} the resData object
				 */
				markFilesSeen: async function (projectID, workspaceID, fileIDs) {
					const method = 'POST';
					const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/datasafe/seen';
					const params = { fileIDs };

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Create a WOPI session
				 *
				 * @param {Object} workspace
				 * @param {Object} file
				 * @param {number} file.id
				 * @param {string} file.name
				 * @param {string} file.encryptedSymmetricKey
				 * @return {Promise<Object>} the resData object
				 */
				createWopiSession: async function (workspace, file) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + file.id + '/wopi';

					const fileKey = await iurio.crypto.handler.beforeCreateWopiSession(workspace, file);
					const params = {
						fileName: file.name,
						fileKey: fileKey,
					};

					return iurio.api.utils.sendRequest(method, url, params);
				},

				/**
				 * Start a document signature process
				 *
				 * @param {Object} workspace
				 * @param {Object} file
				 * @param {number} file.id
				 * @param {string} file.name
				 * @param {string} file.encryptedSymmetricKey
				 * @param {string} signedFileName
				 * @param {Boolean} [isQualified]
				 * @param {string} [locale]
				 * @return {Promise<Object>} the resData object
				 */
				initFileSign: async function (workspace, file, signedFileName, isQualified, locale) {
					const method = 'POST';
					const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/file/' + file.id + '/sign';

					const data = await iurio.crypto.handler.beforeInitFileSign(workspace, file, signedFileName);
					const fileKey = data[0];
					const signedFileKey = data[1];
					const encryptedSignedFileKey = data[2];
					const signedFileNameEnc = data[3];
					const params = {
						fileName: file.name,
						fileKey,
						signedFileName: signedFileNameEnc,
						signedFileKey,
						encryptedSignedFileKey,
						isQualified: !!isQualified,
					};
					if (locale) {
						params.locale = locale;
					}

					return iurio.api.utils.sendRequest(method, url, params);
				},
			},
		},
	},

	/** @namespace */
	task: {
		/**
		 * Add a task
		 *
		 * @param {Object} workspace
		 * @param {number} boardLaneID
		 * @param {string} taskText
		 * @param {Date} [dueDate]
		 * @param {number} [assignedUserId]
		 * @param {number} [sourceTaskID]
		 * @return {Promise<Object>} the resData object
		 */
		add: async function (workspace, boardLaneID, taskText, dueDate, assignedUserId, sourceTaskID) {
			const method = 'POST';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task';

			const encryptedTaskText = await iurio.crypto.handler.encryptName(taskText, workspace);
			const params = {
				boardLaneID: boardLaneID,
				taskText: encryptedTaskText,
			};
			if (dueDate) {
				params.dueDate = dueDate;
			}
			if (assignedUserId) {
				params.assignedUserId = assignedUserId;
			}
			if (sourceTaskID) {
				params.sourceTaskID = sourceTaskID;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Move task to another lane
		 *
		 * @param {number} projectID
		 * @param {number} workspaceID
		 * @param {number} taskID
		 * @param {number} targetBoardLaneID
		 * @param {number} [nextTaskID]
		 * @return {Promise<Object>} the resData object
		 */
		move: async function (projectID, workspaceID, taskID, targetBoardLaneID, nextTaskID) {
			const method = 'POST';
			const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/board/task/' + taskID + '/move';

			const params = { targetBoardLaneID };
			if (nextTaskID) {
				params.nextTaskID = nextTaskID;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Edit a task
		 *
		 * @param {Object} workspace
		 * @param {number} taskID
		 * @param {Date} [dueDate]
		 * @param {string} [taskText]
		 * @param {number} [assignedUserId]
		 * @return {Promise<Object>} the resData object
		 */
		edit: async function (workspace, taskID, dueDate, taskText, assignedUserId) {
			const method = 'PUT';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID;

			const params = {};
			if (taskText) {
				const encryptedTaskText = await iurio.crypto.handler.encryptName(taskText, workspace);
				params.taskText = encryptedTaskText;
			}
			if (dueDate) {
				params.dueDate = dueDate;
			}
			if (assignedUserId) {
				params.assignedUserId = assignedUserId;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Archive a task
		 *
		 * @param {number} projectID
		 * @param {number} workspaceID
		 * @param {number} taskID
		 * @param {boolean} archiveState
		 * @return {Promise<Object>} the resData object
		 */
		archive: async function (projectID, workspaceID, taskID, archiveState) {
			if (!projectID || !workspaceID || !taskID || typeof archiveState === 'undefined') {
				console.error('invalid call of function iurio.api.task.archive');
				return;
			}

			const method = 'PUT';
			const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/board/task/' + taskID + '/archive';
			const params = { archiveState };

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Archive a lane
		 *
		 * @param {number} projectID
		 * @param {number} workspaceID
		 * @param {number} laneID
		 * @param {boolean} archiveState
		 * @return {Promise<Object>} the resData object
		 */
		archiveLane: async function (projectID, workspaceID, laneID, archiveState) {
			if (!projectID || !workspaceID || !laneID || typeof archiveState === 'undefined') {
				console.error('invalid call of function iurio.api.task.archiveLane');
				return;
			}

			const method = 'PUT';
			const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/board/archiveLane';
			const params = {
				archiveState: archiveState,
				laneID: laneID,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Remove a task
		 *
		 * @param {number} projectID
		 * @param {number} workspaceID
		 * @param {number} taskID
		 * @return {Promise<Object>} the resData object
		 */
		remove: async function (projectID, workspaceID, taskID) {
			const method = 'DELETE';
			const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/board/task/' + taskID;

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Check notification for a task
		 *
		 * @param {number} projectID
		 * @param {number} workspaceID
		 * @param {number} taskID
		 * @param {boolean} [clear]
		 * @return {Promise<Object>} the resData object
		 */
		getTaskNotification: async function (projectID, workspaceID, taskID, clear) {
			const method = 'POST';
			const url = '/api/v1/project/' + projectID + '/workspace/' + workspaceID + '/board/task/' + taskID + '/get-notification';

			const params = {};
			if (clear) {
				params.clear = clear;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * List log entries
		 *
		 * @param {Object} workspace
		 * @param {number} taskID
		 * @return {Promise<Object[]>} the resData object
		 */
		listLog: async function (workspace, taskID) {
			const method = 'GET';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/log';

			return iurio.api.utils.sendRequest(method, url);
		},

		listCommentsAndAttachments: async function(workspace, taskID) {
			const method = 'GET';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/comments-and-attachments';

			const symmetricKey = await workspace.loadSymmetricKey();
			const onSuccess = resData => iurio.crypto.handler.onListTaskCommentsAndAttachments(resData, symmetricKey);

			return iurio.api.utils.sendRequest(method, url, onSuccess);
		},

		/** @namespace */
		attachment: {
			/**
			 * Add a task attachment
			 *
			 * @param {Object} workspace
			 * @param {number} taskID
			 * @param {string} attachmentURL
			 * @return {Promise<Object>} the resData object
			 */
			add: async function (workspace, taskID, attachmentURL) {
				const method = 'POST';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/attachment';

				const encryptedUrl = await iurio.crypto.handler.encryptName(attachmentURL, workspace);
				const params = {
					url: encryptedUrl,
				};

				return iurio.api.utils.sendRequest(method, url, params);
			},

			/**
			 * List all task attachments
			 *
			 * @param {Object} workspace
			 * @param {number} taskID
			 */
			list: async function (workspace, taskID) {
				const method = 'GET';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/attachment';

				const symmetricKey = await workspace.loadSymmetricKey();
				const onSuccess = resData => iurio.crypto.handler.onListTaskAttachments(resData, symmetricKey);

				return iurio.api.utils.sendRequest(method, url, onSuccess);
			},

			/**
			 * Remove a task attachment
			 *
			 * @param {Object} workspace
			 * @param {number} taskID
			 * @param {number} attachmentID
			 * @return {Promise<Object>} the resData object
			 */
			remove: async function (workspace, taskID, attachmentID) {
				const method = 'DELETE';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/attachment/' + attachmentID;

				return iurio.api.utils.sendRequest(method, url);
			},
		},

		/** @namespace */
		comment: {
			/**
			 * Edit a task
			 *
			 * @param {Object} workspace
			 * @param {number} taskID
			 * @param {string} comment
			 * @return {Promise<Object>} the resData object
			 */
			add: async function (workspace, taskID, comment) {
				const method = 'POST';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/comment';

				const encryptedComment = await iurio.crypto.handler.encryptName(comment, workspace);
				const params = {
					comment: encryptedComment,
				};

				return iurio.api.utils.sendRequest(method, url, params);
			},

			list: async function (workspace, taskID) {
				const method = 'GET';
				const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/board/task/' + taskID + '/comment';

				const symmetricKey = await workspace.loadSymmetricKey();
				const onSuccess = resData => iurio.crypto.handler.onListTaskComments(resData, symmetricKey);

				return iurio.api.utils.sendRequest(method, url, onSuccess);
			},
		},
	},

	/** @namespace */
	chat: {
		/**
		 * Get chat channel entry points
		 *
		 * @return {Promise<Object>} the resData object
		 */
		getChannelEntryPoints: async function () {
			const method = 'GET';
			const url = '/api/v1/chat/channel';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * List chat messages
		 *
		 * @param {string} channelName
		 * @return {Promise<Object>} the resData object
		 */
		list: async function (channelName, symmetricKey) {
			const method = 'GET';
			const url = '/api/v1/chat/channel/' + channelName + '/list';

			const onSuccess = resData => iurio.crypto.handler.onListChannelMessages(resData, symmetricKey);

			return iurio.api.utils.sendRequest(method, url, onSuccess);
		},

		/**
		 * Update read status for a given channel
		 *
		 * @param {string} channelName
		 * @param {Number} [readUntil]
		 * @return {Promise<Object>} the resData object
		 */
		updateReadStatus: async function (channelName, readUntil) {
			const method = 'PUT';
			const url = '/api/v1/chat/channel/' + channelName;

			const params = {};
			if (readUntil) {
				params.readUntil = readUntil;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Send a chat message
		 *
		 * @param {string} channelName
		 * @param {string} message
		 * @return {Promise<Object>} the resData object
		 */
		sendMessage: async function (channelName, message, symmetricKey) {
			const method = 'POST';
			const url = '/api/v1/chat/channel/' + channelName;

			message = await iurio.crypto.handler.beforeSendChannelMessage(message, symmetricKey);
			const params = { message };

			return iurio.api.utils.sendRequest(method, url, params);
		},
	},

	/** @namespace */
	roles: {
		/**
		 * Add role
		 *
		 * @param {string} name
		 * @param {boolean} isExternal
		 * @param {Object} permissions
		 * @return {Promise<Object>} the resData object
		 */
		addRole: async function (name, isExternal, permissions) {
			const method = 'POST';
			const url = '/api/v1/admin/role';

			const params = {
				name,
				isExternal,
				permissions,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Edit role
		 *
		 * @param {number} roleID
		 * @param {string} [name]
		 * @param {Object} permissions
		 * @return {Promise<Object>} the resData object
		 */
		editRole: async function (roleID, name, permissions) {
			const method = 'PUT';
			const url = '/api/v1/admin/role/' + roleID;

			const params = { permissions };
			if (name) {
				params.name = name;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Delete role
		 *
		 * @param {number} roleID
		 * @param {number} moveRoleID
		 * @return {Promise<Object>} the resData object
		 */
		deleteRole: async function (roleID, moveRoleID) {
			const method = 'DELETE';
			const url = '/api/v1/admin/role/' + roleID;
			const params = { moveRoleID };

			return iurio.api.utils.sendRequest(method, url, params);
		},
	},

	/** @namespace */
	view: {
		/**
		 * Get dashboard view data
		 *
		 * @param {string} [locale]
		 * @return {Promise<Object>} the resData object
		 */
		dashboard: async function (locale) {
			const method = 'GET';
			const url = '/api/v1/views/dashboard';

			const params = {};
			if (locale) {
				params.locale = locale;
			}

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get project view data
		 *
		 * @param {number} projectID
		 * @return {Promise<Object>} the resData object
		 */
		project: async function (projectID) {
			const method = 'GET';
			const url = '/api/v1/views/project/' + projectID;

			return iurio.api.utils.sendRequest(method, url);
		},
	},

	/** @namespace */
	sync: {
		/**
		 * Get sync criteria
		 *
		 * @return {Promise<Object>} the resData object
		 */
		listCriteria: async function () {
			const method = 'GET';
			const url = '/api/v1/sync/list-criteria';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Get sync folders
		 *
		 * @return {Promise<Object>} the resData object
		 */
		listFolders: async function () {
			const method = 'GET';
			const url = '/api/v1/sync/list-folders';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Get sync files
		 *
		 * @param {Object[]} workspaces
		 * @param {number} [syncID]
		 * @return {Promise<Object[]>} the syncList
		 */
		listFiles: async function (workspaces, syncID) {
			const method = 'GET';
			const url = '/api/v1/sync/list-files';

			if (!Array.isArray(workspaces)) {
				throw 'workspaces is not an array';
			} else if (workspaces.length === 0) {
				throw 'workspaces is an empty array';
			}

			let params = {};
			if (syncID) {
				params.syncID = syncID;
			}

			const onSuccess = syncList => iurio.crypto.handler.onListSyncFiles(syncList, workspaces);

			return iurio.api.utils.sendRequest(method, url, params, onSuccess);
		},

		/**
		 * Update sync criterion values
		 *
		 * @param {String} criterion
		 * @param {String[]} values part of or all values
		 * @param {number} total number of values to update
		 * @param {String} hash hash of all values
		 * @return {Promise<Object>} the resData object
		 */
		updateCriterionValues: async function (criterion, values, total, hash) {
			const method = 'POST';
			const url = '/api/v1/sync/update-criteria-values';
			const params = {
				criterion,
				values,
				total,
				hash,
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Get sync criteria values
		 *
		 * @param {Object} workspace
		 * @return {Promise<Object>} the resData object
		 */
		getCriteriaValues: async function (workspace) {
			const method = 'GET';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/sync/get-criteria-values';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Get sync user
		 *
		 * @param {Object} workspace
		 * @return {Promise<Object>} the resData object
		 */
		getSyncUser: async function (workspace) {
			const method = 'GET';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/sync/get-sync-user';

			return iurio.api.utils.sendRequest(method, url);
		},

		/**
		 * Create folder synchronization
		 *
		 * @param {Object} workspace
		 * @param {number} folderID
		 * @param {Object} criteria
		 * @param {boolean} syncUp sync from IURIO to remote application
		 * @param {boolean} syncDown sync from remote application to IURIO
		 * @param {Object} syncUser
		 * @return {Promise<Object>} the resData object
		 */
		create: async function (workspace, folderID, criteria, syncUp, syncDown, syncUser) {
			const method = 'POST';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/folder/' + folderID + '/sync';

			const keys = await iurio.crypto.handler.beforeProjectParticipantAdd(workspace, [syncUser]);
			const params = {
				criteria,
				syncUp,
				syncDown,
				encryptedSymmetricKey: keys[0],
			};

			return iurio.api.utils.sendRequest(method, url, params);
		},

		/**
		 * Remove folder synchronization
		 *
		 * @param {Object} workspace
		 * @param {number} folderID
		 * @return {Promise<Object>} the resData object
		 */
		remove: async function (workspace, folderID) {
			const method = 'DELETE';
			const url = '/api/v1/project/' + workspace.project + '/workspace/' + workspace.id + '/datasafe/folder/' + folderID + '/sync';

			return iurio.api.utils.sendRequest(method, url);
		},
	},

	/** @namespace */
	utils: {
		/**
		 * Send an email
		 *
		 * @param {string} token
		 * @param {string} subject
		 * @param {string} body
		 * @param {string} [name]
		 * @param {string} [key]
		 * @return {Promise<Object>} the resData object
		 */
		sendMail: async function (token, subject, body, name, key) {
			const method = 'POST';
			const url = 'https://iurio.com/api/send_mail_external.php';

			if (key) {
				body = body.replace(/__KEY__/g, key);
			}
			const params = new FormData();
			params.append('token', token);
			params.append('subject', subject);
			params.append('body', body);
			if (name) {
				params.append('name', name);
			}

			const xhr = new XMLHttpRequest();
			return new Promise(function (resolve, reject) {
				xhr.open(method, url, true);

				xhr.responseType = 'text';
				xhr.onload = async function () {
					resolve(this.response);
				};
				xhr.onerror = reject;
				xhr.send(params);
			});
		},

		/**
		 * Send a template email
		 *
		 * @param {string} token
		 * @param {string} [subject]
		 * @param {string} [name]
		 * @param {Object} [variables]
		 * @return {Promise<Object>} the resData object
		 */
		sendMailTemplate: async function (token, subject, name, variables) {
			const method = 'POST';
			const url = 'https://iurio.com/api/send_mail_external_template.php';

			const params = new FormData();
			params.append('token', token);
			if (subject) {
				params.append('subject', subject);
			}
			if (name) {
				params.append('name', name);
			}
			if (variables) {
				for (let key in variables) {
					params.append('variables[' + key + ']', variables[key]);
				}
			}

			const xhr = new XMLHttpRequest();
			return new Promise(function (resolve, reject) {
				xhr.open(method, url, true);

				xhr.responseType = 'text';
				xhr.onload = async function () {
					resolve(this.response);
				};
				xhr.onerror = reject;
				xhr.send(params);
			});
		},

		/**
		 * Download a file
		 *
		 * @param {string} URL
		 * @param {string} [name]
		 * @param {Object} [handlers]
		 * @return {Promise<File>} the resData object
		 */
		downloadFile: async function (url, name, handlers) {
			const method = 'GET';
			const xhr = new XMLHttpRequest();
			return new Promise(function (resolve, reject) {
				xhr.open(method, url, true);
				xhr.responseType = 'blob';
				xhr.onload = async function () {
					const r = this.response;
					if (!r.arrayBuffer) {
						r.arrayBuffer = iurio.crypto.handler.helpers.arrayBufferReader(r);
					}
					let data;
					try {
						data = await r.arrayBuffer();
					} catch (unusedErr) {
						data = await this.helpers.arrayBufferReader(r)();
					}
					if (!name) {
						const start = url.lastIndexOf('/') + 1;
						const end = url.indexOf('?', start);
						name = url.substring(start, end === -1 ? undefined : end) || 'file';
					}
					const mimeType = iurio.crypto.handler.helpers.getMimeType(name);
					resolve(new File([data], name, { type: mimeType }));
				};
				if (handlers) {
					if (handlers.onProgress) {
						xhr.onprogress = handlers.onProgress;
					}
					if (handlers.onAbort) {
						xhr.onabort = handlers.onAbort;
					}
				}
				xhr.onerror = reject;
				xhr.send();
			});
		},

		/**
		 * Send a request via websocket
		 *
		 * @param {string} method
		 * @param {string} url
		 * @param {Object} [params]
		 * @param {Function} [onSuccess]
		 */
		sendRequest: function (method, url, params, onSuccess) {
			if (typeof params === 'function') {
				onSuccess = params;
				params = undefined;
			}

			method = method.toUpperCase();
			let wsReq;
			let isGet = false;
			switch (method) {
				case 'DELETE':
					wsReq = io.socket.delete;
					break;

				case 'GET':
					isGet = true;
					wsReq = io.socket.get;
					break;

				case 'PATCH':
					wsReq = io.socket.patch;
					break;

				case 'POST':
					wsReq = io.socket.post;
					break;

				case 'PUT':
					wsReq = io.socket.put;
					break;

				default:
					throw 'Unknown method: ' + method;
			}
			wsReq = wsReq.bind(io.socket);

			if (!isGet) {
				if (!params) {
					params = {};
				}
				params._csrf = iurioData.csrf;
			}

			return new Promise(function (resolve, reject) {
				wsReq(url, params, function (resData, jwRes) {
					if (jwRes.error) {
						return reject(jwRes);
					}
					if (typeof onSuccess === 'function') {
						resolve(onSuccess(resData));
					} else {
						resolve(resData);
					}
				});
			});
		},
	},
};

if (typeof module !== 'undefined') {
	module.exports = {
		api: iurio.api,
		crypto: iurio.crypto,
	};
}
